import React from 'react'
import PageLayout from '../components/PageLayout';
import Button from '../components/Button';
import styled from 'styled-components';
import { centerize, centerizeBody } from '../utils/commonCSS';
import { Link } from 'gatsby';

function NotFound({className}) {
  return (
    <PageLayout title="OOPS" noPartiallyActive>
      <div className={className}>
        <h2>
          PAGE NOT FOUND
        </h2>
        <p>
          The page you requested does not exist.
        </p>
        <Link to="/">
          <Button>Back to home</Button>
        </Link>
      </div>
    </PageLayout>
  )
}

export default styled(NotFound)`
  ${centerize}
  ${centerizeBody}
`;
